<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12>
                <v-card style="margin:-16px;">
                    <div class="profile-tab-container">
                        <div class="edit-profile-button">
                        </div>
                        <div class="profile-intro">
                            <h2>{{user.full_name}}</h2>
                            <div class="title-intro">
                                <h4>
                                    <span v-if="user.primary_mobile"><v-icon size="small"
                                                                             color="white">phone_android</v-icon>
                                    {{user.primary_mobile}} / </span>
                                    <span v-if="user.email"><v-icon size="small" color="white">alternate_email</v-icon>
                                    {{user.email}}</span>
                                </h4>
                            </div>
                        </div>

                        <div class="tab-content">
                            <div class="t-container">
                                <div @click="$auth.can('student-update') ? onPickFile():''" class="profile-image">
                                    <div :class="'image ' + (cropperImg.uploading===true ? 'uploading':'')">
                                        <h3 v-if="cropperImg.uploading">Uploading...</h3>
                                        <p v-if="cropperImg.uploading">Please wait...</p>
                                        <img v-if="!cropperImg.uploading" :src="user.profile_image"
                                             alt="User Image">
                                        <input type="file"
                                               style="display: none"
                                               ref="image"
                                               accept="image/*"
                                               @change="onFilePicked">
                                    </div>
                                </div>
                                <div class="t-hold">
                                </div>
                            </div>
                        </div>
                    </div>
                </v-card>
            </v-flex>
            <div class="profile-contents">
                <v-layout row wrap>
                    <v-flex xs12>
                        <!--<v-card class="mb-2 elevation-1 pa-4">-->
                        <v-container class="user-address user-profile-info" grid-list-md>
                            <h2>
                                <v-icon>assignment_ind</v-icon>
                                Personal Information


                                <a v-if="!updatePersonal" @click.prevent="updatePersonal=true,updatePassword=false"
                                   class="edit-info"
                                   href="">
                                    <v-icon small>edit</v-icon>
                                    <u>Update Personal Info</u>
                                </a>

                                <a v-else @click.prevent="updatePersonal=false" class="edit-info" href="">
                                    <v-icon small color="#f44336">edit</v-icon>
                                    <u>Cancel Update Personal Info</u>
                                </a>

                                <a v-if="!updatePassword" @click.prevent="updatePassword=true,updatePersonal=false"
                                   class="edit-info"
                                   href="">
                                    <v-icon small>lock</v-icon>
                                    <u>Update Password</u>
                                </a>

                                <!--style="color:#fc7970"-->
                                <a v-else @click.prevent="updatePassword=false" class="edit-info"
                                   href="">
                                    <v-icon small color="#f44336">lock</v-icon>
                                    <u>Cancel Update Password</u>
                                </a>
                            </h2>
                            <v-card>
                                <personal v-if="!updatePersonal && !updatePassword" :user="user"/>
                                <password-update @onCancel="updatePassword=false"
                                                 @onUpdate="updatePassword=false,fetchMyProfileInfo()"
                                                 v-if="!updatePersonal && updatePassword" :user="user"/>
                                <personal-update @onCancel="updatePersonal=false"
                                                 @onUpdate="updatePersonal=false,fetchMyProfileInfo()"
                                                 v-if="updatePersonal && !updatePassword" :user="user"/>
                            </v-card>
                        </v-container>
                        <!--</v-card>-->
                    </v-flex>
                </v-layout>
            </div>
        </v-layout>
        <v-dialog v-model="cropperImg.cropDialog" persistent max-width="600">
            <v-card>
                <vue-cropper
                        ref='cropper'
                        :guides="true"
                        :view-mode="2"
                        drag-mode="crop"
                        :auto-crop-area="0.5"
                        :min-container-width="100"
                        :min-container-height="100"
                        :background="false"
                        :min-crop-box-width="200"
                        :max-crop-box-width="200"
                        :min-crop-box-height="200"
                        :max-crop-box-height="200"
                        :aspect-ratio="1"
                        :rotatable="true"
                        :src="cropperImg.imgSrc"
                        alt="Source Image"
                        :img-style="{ 'width': '500px', 'height': '500px' }">
                </vue-cropper>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" flat @click="cropperImg.cropDialog = false">Cancel</v-btn>
                    <v-btn color="green darken-1" flat @click="cropImage">Upload</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
    import Form from '@/library/Form';
    import Personal from './modules/personal'
    import PersonalUpdate from './modules/PersonalUpdate'
    import PasswordUpdate from './modules/PasswordUpdate'
    import VueCropper from 'vue-cropperjs';
    import ImageCompressor from 'image-compressor.js';

    // import {mapState, mapActions, mapMutations} from 'vuex'


    export default {
        components: {
            Personal,
            PersonalUpdate,
            PasswordUpdate,
            VueCropper
        },
        data: () => ({
            cropperImg: {
                cropDialog: false,
                imgSrc: '',
                cropImg: '',
                uploadCropped: {},
                name: null,
                uploading: false
            },
            updatePersonal: false,
            updatePassword: false,
            user: {},
            form: new Form({}, '/api/student'),
            uploadProfile: new Form({image: ''}, '/auth/me/profile-image-update'),
            transport: {},
            activeBtn: 'general',
            direction: 'top',
            fab: false,
            fling: false,
            hover: false,
            tabs: null,
            top: false,
            right: true,
            bottom: true,
            left: false,
            transition: 'slide-y-reverse-transition',
            staff: {}
        }),
        mounted() {
            this.fetchMyProfileInfo();
        },
        methods: {
            fetchMyProfileInfo() {
                this.$rest.get('/auth/me').then(({data}) => {
                    this.user = data;
                });
            },
            cropImage() {
                // get image data for post processing, e.g. upload or setting image src
                const canvas = this.$refs.cropper.getCroppedCanvas();
                this.cropperImg.cropImg = canvas.toDataURL();
                let vm = this;
                canvas.toBlob(function (blob) {
                    const imageCompressor = new ImageCompressor();
                    imageCompressor.compress(blob, {quality: .3}).then((result) => {
                        result.name = 'image';
                        vm.cropperImg.uploadCropped = result;
                        vm.cropperImg.cropDialog = false;

                        //upload to server
                        if (Object.keys(vm.cropperImg.uploadCropped).length > 0) {
                            vm.uploadProfile.image = vm.cropperImg.uploadCropped;
                            vm.uploadProfile.endpoint = '/auth/me/profile-image-update';
                            vm.uploadProfile.fireFetch = false;
                            vm.cropperImg.uploading = true;
                            vm.uploadProfile.store().then(() => {
                                vm.fetchMyProfileInfo();
                            }).finally(() => {
                                vm.cropperImg.uploading = false;
                            });
                        }

                    });

                }, 'image/jpeg', 0.3);
            },

            onPickFile() {
                this.$refs.image.click();
            },
            onFilePicked(e) {
                const file = e.target.files[0];
                if (!file.type.includes('image/')) {
                    return;
                }
                if (typeof FileReader === 'function') {
                    const reader = new FileReader();
                    reader.onload = (event) => {
                        this.cropperImg.imgSrc = event.target.result;
                        this.cropperImg.name = file.name;
                        this.$refs.cropper.replace(event.target.result);
                    };
                    reader.readAsDataURL(file);
                } else {
                    alert('Sorry, FileReader API not supported');
                }
                this.cropperImg.cropDialog = true;
            },
        }
    }
</script>
<style lang="scss">
    .user_add_form {
        .v-chip, .v-chip .v-chip__content {
            border-radius: 2px;
            color: white;
        }
    }

    .profile-contents {
        width: 100%;
        margin: 26px auto;
    }

    .container {
        .profile-tab-container {
            min-height: 180px;
            background: linear-gradient(to right, #18e0f5, #135aa5 80%);
            position: relative;
            display: flex;
            .profile-intro {
                position: absolute;
                bottom: 65px;
                left: 250px;
                h2 {
                    font-weight: bold;
                    color: white;
                }
                .title-intro {
                    display: flex;
                    h4 {
                        color: white;
                        line-height: 10px;
                        align-self: center;
                        margin-right: 20px;
                    }
                }
            }
            .edit-profile-button {
                position: absolute;
                bottom: 65px;
                color: white !important;
                right: 150px;
                a {
                    text-decoration: none;
                }
            }
            .tab-content {
                align-self: flex-end;
                background-color: white;
                height: 55px;
                width: 100%;
                .t-container {
                    height: inherit;
                    width: 85%;
                    margin: auto;
                    display: flex;
                    .t-hold {
                        flex: 3;
                    }
                    .profile-image {
                        flex: 1;
                        position: relative;
                        cursor: pointer;

                        .image {
                            background-color: #eee;
                            height: 120px;
                            width: 120px;
                            position: absolute;
                            left: 30px;
                            border-radius: 120px;
                            border: 5px solid white;
                            margin-top: -70px;
                            z-index: 2;
                            overflow: hidden;
                            img {
                                width: 100%;
                            }
                            &:hover {
                                &:after {
                                    content: '';
                                    background-color: black;
                                    opacity: 0.5;
                                    position: absolute;
                                    left: 0;
                                    /*top: 0;*/
                                    height: 120px;
                                    width: 120px;
                                    top: 0;
                                }
                            }
                        }

                    }
                }
            }
        }
    }

    .v-item-group.v-bottom-nav {
        box-shadow: none;
    }

    .v-list > div:hover {
        cursor: pointer;
        background-color: #eee;
    }

    .update-form-profile {
        h2 {
            color: #777;
            margin-bottom: 8px;
        }
    }

    .user-profile-info {
        h2 {
            color: #777;
            font-weight: normal;
            font-size: 20px;
            margin-bottom: 12px;
            a.edit-info {
                display: inline-block;
                float: right;
                margin-right: 15px;
                text-decoration: none;
                font-size: 14px;
                color: #555;
            }
        }
        h3 {
            color: #666;
            font-size: 15px;

            font-weight: normal;
        }
        small.input-label {
            color: #777;
        }
    }
</style>